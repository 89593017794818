import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import HexagonRight from "../../../static/arrow/HexagonRight.svg"
import LinkExternalInternal from "../link/link"

const ReadMore = styled.div`
  position: relative;
  &:hover {
    animation: pulse 1s infinite;
    animation-timing-function: linear;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    75% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
`

const ReadMoreText = styled.div`
  font-family: "Fira Sans";
  font-weight: bold;
  text-decoration: underline;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 20px;
  text-align: left;
  color: #454d62;
  &:hover {
    color: black;
  }
}
`

const ReadMoreHexagon = props => {
  return (
    <LinkExternalInternal to={props.link} className="pulse">
      <Grid
        container
        alignItems="center"
        style={{ margin: 10, ...props.style }}
      >
        <ReadMoreText>Read more</ReadMoreText>
        <ReadMore>
          <img
            src={HexagonRight}
            style={{ width: 30, height: 30, padding: 3 }}
          />
        </ReadMore>
      </Grid>
    </LinkExternalInternal>
  )
}

export default ReadMoreHexagon
