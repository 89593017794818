import React from "react"

import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

import Fade from "react-reveal/Fade"

const JumboContainer = styled.div`
  min-height: 250px;
  width: 100%;
  background: linear-gradient(#1e4778 0%, #09366b 100%);
  display: flex;
  vertical-align: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  @media screen and (max-width: 690px) {
    display: block;
  }
`

const JumboText = styled.h2`
  color: white;
  text-align: center;
  width: 100%;
  padding: 3%;
  @media screen and (max-width: 690px) {
    margin: 0px;
  }
`

const JumboItemContainer = props => {
  return (
    <Grid container justify={"center"}>
      {props.items.map((item, index) => {
        return (
          <Grid key={index} item xs={12} sm={4}>
            {React.cloneElement(props.component, { ...item })}
          </Grid>
        )
      })}
    </Grid>
  )
}

const Jumbotron = props => {
  return (
    <JumboContainer>
      <Container style={{ overflow: "hidden" }}>
        <JumboText>{props.title}</JumboText>
        <Fade right>{props.section}</Fade>
      </Container>
    </JumboContainer>
  )
}

export default Jumbotron
export { JumboItemContainer }
